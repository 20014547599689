import React from "react"
import Layout from "../components/Layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const Home = () => {
  return (
    <Layout>
      <main className="page">
        <header className="hero">
          <StaticImage
            src="../assets/images/corner-sign-2.jpeg"
            alt="Beard Law Company Building"
            className="hero-img"
            placeholder="traced.svg"
            layout="fullWidth"
          ></StaticImage>
          <div className="hero-container"></div>
        </header>
        <div className="home-info">
          {/* <h4>Call Now:</h4> */}
          <Link to="tel:8145480028" className="btn">
            (814) 548-0028
          </Link>
          <h1>Beard Law Company</h1>
          <h2>101 North Allegheny Street, Bellefonte, PA 16823</h2>
        </div>
      </main>
    </Layout>
  )
}

export default Home
